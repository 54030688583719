<template>
  <div class="floor">
    <div class="floor-link">
      <!-- <span><a
        target="_blank"
        href="https://www.gg.com/about.html"
      >关于我们</a></span>
      <span><a
        target="_blank"
        href="https://www.gg.com/cooperation.html"
      >商务合作</a></span>
      <span><a
        target="_blank"
        href="https://www.gg.com/contact.html"
      >联系我们</a></span> -->
      <span><router-link to="/protocol">用户协议</router-link></span>
      <span><router-link to="/privacy">隐私协议</router-link></span>
      <span><router-link
        to="/guardianship"
      >家长监护</router-link></span>
    </div>
    <p>
      健康游戏忠告：抵制不良游戏 拒绝盗版游戏 注意自我保护 谨防上当受骗
      适度游戏益脑 沉迷游戏伤身 合理安排时间 健康享受生活
    </p>
    <p class="record">
      <a
        target="_blank"
        href="https://beian.miit.gov.cn/#/Integrated/index"
      >网站备案号：沪ICP备14010092号-7</a>
    </p>
    <p>本平台游戏适合18岁以上人群</p>
    <p>上海域起网络科技有限公司</p>
    <p>公司地址：中国(上海)自由贸易试验区芳春路400号1幢3层  电话: 021-50129861</p>
    <p>©Team Shanghai Alice</p>
    <p>©GOOD SMILE COMPANY, INC. / NextNinja Co., Ltd.</p>
  </div>
</template>
<script>
export default {
  name: 'Floor',
  data() {
    return {}
  }
}
</script>
<style lang="scss" scoped>
.floor {
  height: 257px;
  position: relative;
  bottom: 0;
  text-align: center;
  background-color: #1b2128;
  color: #4a5663;
  .floor-link {
    cursor: pointer;
    line-height: 30px;
    padding-top: 38px;
    font-size: 13px;
    a {
      text-decoration: none;
      color: #ffffff;
      padding-left: 5px;
    }
  }
  p {
    font-size: 13px;
    line-height: 22px;
  }
  .record {
    cursor: pointer;
    a {
      color: #4a5663;
    }
  }
}
</style>
